import React from 'react';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/locale/ru_RU';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import './index.css';
import { BrowserRouter, Route, Routes, Navigate, } from 'react-router-dom';
import App from '@/App';
var root = ReactDOM.createRoot(document.getElementById('root'));
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});
dayjs.extend(localeData);
dayjs.locale('ru');
root.render(<ConfigProvider locale={ru_RU} theme={{
        token: {
            colorPrimary: '#05588f',
        },
    }}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route element={<App />} path="/"/>
          <Route path="*" element={<Navigate to="/" replace/>}/>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  </ConfigProvider>);
