import dayjs from 'dayjs';
var columns = function () { return ([
    {
        title: 'Отделение',
        dataIndex: 'office_name',
        fixed: 'left',
        width: 200,
    },
    {
        title: 'Дата',
        dataIndex: 'close_date',
        fixed: 'left',
        width: 120,
        render: function (value) { return (dayjs(value).format('DD.MM.YYYY')); },
    },
    {
        title: 'Очередь',
        dataIndex: 'queue_name',
        fixed: 'left',
        width: 240,
    },
    {
        title: '08:00 09:00',
        children: [
            {
                title: 'Кол-во обсл-ных кл-в',
                align: 'center',
                dataIndex: ['08', 'tickets'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
            {
                title: 'кол-во опер-в',
                align: 'center',
                dataIndex: ['08', 'operators'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
        ],
    },
    {
        title: '09:00 10:00',
        children: [
            {
                title: 'Кол-во обсл-ных кл-в',
                align: 'center',
                dataIndex: ['09', 'tickets'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
            {
                title: 'кол-во опер-в',
                align: 'center',
                dataIndex: ['09', 'operators'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
        ],
    },
    {
        title: '10:00 11:00',
        children: [
            {
                title: 'Кол-во обсл-ных кл-в',
                align: 'center',
                dataIndex: ['10', 'tickets'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
            {
                title: 'кол-во опер-в',
                align: 'center',
                dataIndex: ['10', 'operators'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
        ],
    },
    {
        title: '11:00 12:00',
        children: [
            {
                title: 'Кол-во обсл-ных кл-в',
                align: 'center',
                dataIndex: ['11', 'tickets'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
            {
                title: 'кол-во опер-в',
                align: 'center',
                dataIndex: ['11', 'operators'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
        ],
    },
    {
        title: '12:00 13:00',
        children: [
            {
                title: 'Кол-во обсл-ных кл-в',
                align: 'center',
                dataIndex: ['12', 'tickets'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
            {
                title: 'кол-во опер-в',
                align: 'center',
                dataIndex: ['12', 'operators'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
        ],
    },
    {
        title: '13:00 14:00',
        children: [
            {
                title: 'Кол-во обсл-ных кл-в',
                align: 'center',
                dataIndex: ['13', 'tickets'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
            {
                title: 'кол-во опер-в',
                align: 'center',
                dataIndex: ['13', 'operators'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
        ],
    },
    {
        title: '14:00 15:00',
        children: [
            {
                title: 'Кол-во обсл-ных кл-в',
                align: 'center',
                dataIndex: ['14', 'tickets'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
            {
                title: 'кол-во опер-в',
                align: 'center',
                dataIndex: ['14', 'operators'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
        ],
    },
    {
        title: '15:00 16:00',
        children: [
            {
                title: 'Кол-во обсл-ных кл-в',
                align: 'center',
                dataIndex: ['15', 'tickets'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
            {
                title: 'кол-во опер-в',
                align: 'center',
                dataIndex: ['15', 'operators'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
        ],
    },
    {
        title: '16:00 17:00',
        children: [
            {
                title: 'Кол-во обсл-ных кл-в',
                align: 'center',
                dataIndex: ['16', 'tickets'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
            {
                title: 'кол-во опер-в',
                align: 'center',
                dataIndex: ['16', 'operators'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
        ],
    },
    {
        title: '17:00 18:00',
        children: [
            {
                title: 'Кол-во обсл-ных кл-в',
                align: 'center',
                dataIndex: ['17', 'tickets'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
            {
                title: 'кол-во опер-в',
                align: 'center',
                dataIndex: ['17', 'operators'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
        ],
    },
    {
        title: '18:00 19:00',
        children: [
            {
                title: 'Кол-во обсл-ных кл-в',
                align: 'center',
                dataIndex: ['18', 'tickets'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
            {
                title: 'кол-во опер-в',
                align: 'center',
                dataIndex: ['18', 'operators'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
        ],
    },
    {
        title: '19:00 20:00',
        children: [
            {
                title: 'Кол-во обсл-ных кл-в',
                align: 'center',
                dataIndex: ['19', 'tickets'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
            {
                title: 'кол-во опер-в',
                align: 'center',
                dataIndex: ['19', 'operators'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
        ],
    },
    {
        title: '20:00 21:00',
        children: [
            {
                title: 'Кол-во обсл-ных кл-в',
                align: 'center',
                dataIndex: ['20', 'tickets'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
            {
                title: 'кол-во опер-в',
                align: 'center',
                dataIndex: ['20', 'operators'],
                render: function (value) { return (Number(value) ? value : 0); },
            },
        ],
    },
    {
        title: 'Всего талонов обслужено',
        dataIndex: 'ticket_count',
        align: 'center',
    },
    {
        title: 'Всего опер-в',
        dataIndex: 'operator_count',
        align: 'center',
    },
]); };
export default columns;
