var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Button, DatePicker, Drawer, Form, Select, Table, } from 'antd';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import { useMutation, useQuery } from 'react-query';
import { getReport, getFilters, exportReport } from '@/utils/rest';
import columns from '@/utils/tableColumns';
dayjs.extend(utcPlugin);
var defaultFilters;
function App() {
    var _a, _b, _c;
    var _d = useState([dayjs().startOf('month'), dayjs().endOf('day')]), dates = _d[0], setDates = _d[1];
    var _e = useState(), filters = _e[0], setFilters = _e[1];
    var _f = useState({
        companies: undefined,
        offices: undefined,
        queues: undefined,
    }), selectedFilters = _f[0], setSelectedFilters = _f[1];
    var _g = useState(false), openDrawer = _g[0], setOpenDrawer = _g[1];
    var _h = useQuery(['hourly', dates, selectedFilters], function () { return getReport(dates, selectedFilters); }), data = _h.data, isLoading = _h.isLoading, refetch = _h.refetch;
    useQuery('filters', getFilters, {
        onSuccess: function (filtersData) {
            setFilters(filtersData);
            defaultFilters = filtersData;
        },
    });
    var _j = useMutation({
        mutationFn: function (variables) { return exportReport(variables); },
    }), mutate = _j.mutate, exportLoading = _j.isLoading;
    var changeDates = function (selectedDates) {
        setDates(selectedDates);
    };
    var toggleDrawer = function () {
        setOpenDrawer(function (prev) { return !prev; });
    };
    var submitFilters = function (values) {
        setSelectedFilters(values);
        toggleDrawer();
    };
    var handleExport = function () {
        mutate(data);
    };
    var changeValues = function (_changedValue, currentValues) {
        var _a, _b, _c, _d;
        var companies = currentValues.companies, offices = currentValues.offices;
        var newFilters = __assign({}, filters);
        if (companies) {
            if (companies.length === 0) {
                newFilters.offices = defaultFilters.offices;
                setFilters(newFilters);
            }
            else {
                newFilters.offices = (_a = filters === null || filters === void 0 ? void 0 : filters.offices) === null || _a === void 0 ? void 0 : _a.filter(function (el) { return companies.includes(String(el.company_id)); });
                var officeIds_1 = new Set((_b = filters === null || filters === void 0 ? void 0 : filters.offices) === null || _b === void 0 ? void 0 : _b.map(function (el) { return String(el.id); }));
                newFilters.queues = (_c = filters === null || filters === void 0 ? void 0 : filters.queues) === null || _c === void 0 ? void 0 : _c.filter(function (el) { return el.offices.some(function (id) { return officeIds_1.has(String(id)); }); });
                setFilters(newFilters);
            }
        }
        if (offices) {
            if (offices.length === 0) {
                newFilters.queues = defaultFilters.queues;
                setFilters(newFilters);
            }
            else {
                newFilters.queues = (_d = filters === null || filters === void 0 ? void 0 : filters.queues) === null || _d === void 0 ? void 0 : _d.filter(function (el) { return el.offices.some(function (id) { return offices.includes(String(id)); }); });
                setFilters(newFilters);
            }
        }
    };
    var resetFilters = function () {
        setSelectedFilters({
            companies: undefined,
            offices: undefined,
            queues: undefined,
        });
    };
    return (<>
      <Drawer title="Фильтры" width={600} open={openDrawer} destroyOnClose onClose={toggleDrawer}>
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onFinish={submitFilters} onValuesChange={changeValues} initialValues={selectedFilters}>
          <Form.Item label="По компаниям" name="companies">
            <Select mode="multiple" optionFilterProp="label">
              {(_a = filters === null || filters === void 0 ? void 0 : filters.companies) === null || _a === void 0 ? void 0 : _a.map(function (company) { return (<Select.Option label={company.name} key={company.id}>{company.name}</Select.Option>); })}
            </Select>
          </Form.Item>
          <Form.Item label="По офисам" name="offices">
            <Select mode="multiple" optionFilterProp="label">
              {(_b = filters === null || filters === void 0 ? void 0 : filters.offices) === null || _b === void 0 ? void 0 : _b.map(function (office) { return (<Select.Option label={office.name} key={office.id}>{office.name}</Select.Option>); })}
            </Select>
          </Form.Item>
          <Form.Item label="По услугам" name="queues">
            <Select mode="multiple">
              {(_c = filters === null || filters === void 0 ? void 0 : filters.queues) === null || _c === void 0 ? void 0 : _c.map(function (queue) { return (<Select.Option key={queue.name}>{queue.name}</Select.Option>); })}
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
            <Button className="filter__button" htmlType="submit" type="primary">Применить</Button>
          </Form.Item>
        </Form>
      </Drawer>
      <h1 className="page__header">Почасовой отчёт</h1>
      <div className="page__content">
        <div className="filter__box">
          <DatePicker.RangePicker format="DD.MM.YYYY" value={dates} onChange={changeDates} style={{ width: '250px' }}/>
          <div className="filter__button__box">
            <div>
              <Button className="filter__button" onClick={function () { return refetch; }} type="primary">Применить</Button>
              <Button className="filter__button" onClick={toggleDrawer} type="primary">Фильтры</Button>
              <Button className="filter__button" onClick={resetFilters} type="primary">
                Сбросить все
                фильтры
              </Button>
            </div>
            <div>
              <Button className="filter__button" type="primary" onClick={handleExport} loading={exportLoading}>
                Экспортировать
              </Button>
            </div>
          </div>
        </div>
        <Table rowKey={function (rec) { return "".concat(rec.office_nmae).concat(rec.queue_name).concat(rec.close_date); }} dataSource={data} columns={columns()} loading={isLoading} bordered scroll={{ x: 2600, y: 500 }} size="small"/>
      </div>
    </>);
}
export default App;
